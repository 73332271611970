import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { en, de, da, fr } from './locales';

const resources = {
  en,
  de,
  da,
  fr
} as const;

i18n
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: [
      'addressBook',
      'admin',
      'approval',
      'assets',
      'auth',
      'common',
      'currency',
      'merchantCategories',
      'merchantPlatform',
      'merchantTip',
      'profile',
      'SAShutdown',
      'sell',
      'toast',
      'verification',
      'wallet',
    ],
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });

export default i18n;
